export default [
  {
    header: "Accessibility",
  },
  // {
  //   title: 'Chat',
  //   route: 'apps-chats',
  //   icon: 'MessageCircleIcon',
  //   resource: 'Support',
  //   action: 'Support',
  // },
  {
    title: "Financial",
    icon: "ZapIcon",
    route: "apps-financials",
  },
  {
    title: "Tickets",
    icon: "MessageSquareIcon",
    route: "apps-allTickets",
    tag: 0,
    hasNotif: true,
  },
  {
    title: "Users",
    icon: "UserIcon",
    children: [
      {
        title: "List",
        route: "apps-users-list",
      },
      {
        title: "Fake users",
        route: "apps-fake-users-reports",
      },
      {
        title: "Fake names",
        route: "apps-fake-names-list",
      },
      {
        title: "Tatum accounts",
        route: "apps-users-tatum",
      },
    ],
  },
  {
    title: "Candles",
    icon: "ActivityIcon",
    children: [
      {
        title: "List",
        route: "apps-candles-list",
      },
    ],
  },
  {
    title: "Trades",
    icon: "DollarSignIcon",
    children: [
      {
        title: "List",
        route: "apps-trades-list",
      },
    ],
  },
  {
    title: "Wallets",
    icon: "CreditCardIcon",
    children: [
      {
        title: "List",
        route: "apps-wallets-list",
      },
    ],
  },
  {
    title: "Referrals",
    icon: "Link2Icon",
    children: [
      {
        title: "Referral Links",
        route: "apps-referral-links-list",
      },
      {
        title: "Referred Users",
        route: "apps-referral-users-list",
      },
      {
        title: "Cashout Requests",
        route: "apps-referral-cash-request",
      },
      {
        title: "Commission Cashout Requests",
        route: "apps-referral-commission-cash-request",
      },
    ],
  },
  {
    title: "Blockchains",
    icon: "GlobeIcon",
    children: [
      {
        title: "List",
        route: "apps-blockchains-list",
      },
    ],
  },
  {
    title: "Transactions",
    icon: "DollarSignIcon",
    route: "apps-transactions-list",
    tag: 0,
  },
  {
    title: "Static pages",
    icon: "TrelloIcon",
    children: [
      {
        title: "about-us",
        route: "apps-static-pages-about-us",
      },
      {
        title: "terms",
        route: "apps-static-pages-terms",
      },
      {
        title: "rule",
        route: "apps-static-pages-rule",
      },
    ],
  },
  {
    title: "Dynamic pages",
    icon: "LayersIcon",
    children: [
      {
        title: "list",
        route: "apps-dynamic-pages-list",
      },
      {
        title: "create dynamic page",
        route: "apps-dynamic-pages-create",
      },
    ],
  },
  // {
  //   title: 'orders',
  //   icon: 'TrelloIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-orders-list',
  //       resource: 'Accountent',
  //       action: 'Accountent',
  //     },
  //   ],
  // },
  // {
  //   title: 'Financial',
  //   icon: 'DollarSignIcon',
  //   children: [
  //     {
  //       title: 'Payments list',
  //       route: 'apps-financial-payments-list',
  //       resource: 'Accountent',
  //       action: 'Accountent',
  //     },
  //   ],
  // },
  {
    title: "Blogs",
    icon: "BookOpenIcon",
    // resource: 'Blogger',
    // action: 'Blogger',
    children: [
      {
        title: "List",
        route: "pages-blog-list",
        resource: "Blogger",
        action: "Blogger",
      },
      {
        title: "Add",
        route: "pages-blog-create",
        resource: "Blogger",
        action: "Blogger",
      },
      {
        title: "Categories",
        route: "pages-blog-categories-list",
        resource: "Blogger",
        action: "Blogger",
      },
      // {
      //   title: 'Comments',
      //   route: 'pages-blog-comments',
      //   resource: 'Blogger',
      //   action: 'Blogger',
      // },
    ],
  },
  {
    title: "Popup",
    icon: "MaximizeIcon",
    route: "pages-popup-list",
  },
  {
    title: "Bonus",
    icon: "GiftIcon",
    route: "pages-bonus-list",
  },
  {
    title: "User Level",
    icon: "AwardIcon",
    route: "apps-user-levels-list",
  },
];
