<template>
  <li
    v-if="show && canViewVerticalNavMenuLink(reActiveItem)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: reActiveItem.disabled,
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="reActiveItem.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(reActiveItem.title) }}</span>
      <b-badge
        v-if="reActiveItem.tag"
        pill
        :variant="reActiveItem.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ reActiveItem.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import { WalletGetAllRequest } from "@/libs/Api/Wallet";
import { UsersGetUnreadCountRequest } from "@/libs/Api/Users";

export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    BLink,
    BBadge,
  },
  async created() {
    await this.getNotifications();
    this.show = true;
  },
  methods: {
    async getNotifications() {
      let _this = this;
      if (this.item.hasNotif) {
        let usersGetUnreadCountRequest = new UsersGetUnreadCountRequest(_this);
        await usersGetUnreadCountRequest.fetch(
          function (content) {
            if (_this.item.title === "Tickets") {
              _this.item.tag = content.unreadTicketCount;
            }
            if (_this.item.title === "Transactions") {
              _this.item.tag = content.unreadTransactionCount;
            }
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reActiveItem() {
      return this.item;
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>
